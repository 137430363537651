import React from 'react';
import TelegramLogo from '../../../Assets/mainPage/telegram.svg'
import {LINK_TO_API_SUPPORT} from "../../../constants/links";

export const ClaimSection = () => {
    return (
        <div className="main-page-claim">
            <h5 className="main-page-claim-text main-page-headings-common">
                To claim fee rebates and request settlement, please reach out to RetroBridge API Support
            </h5>

            <div className="main-page-claim-btn-container">
                <a href={LINK_TO_API_SUPPORT} target="_blank" className="main-page-button btn claim-button-width" rel="noreferrer">
                    <img className="main-page-claim-btn-icon" src={TelegramLogo} alt=""/>
                    Claim Rebate Fees
                </a>
            </div>
        </div>
    );
};
