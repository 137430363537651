import React, {useEffect, useState} from 'react';
import {FeesInfo} from "./FeesInfo";
import {DatePickerComponent} from "./DatePickerComponent";
import {useUserInfoContext} from "../../../context/UserInfoContext";
import {handleDateChange} from "../../../utils/handleDateChange";
import {EmptyFeeState} from "./EmptyFeeState";

export const FeeRebateCard = () => {
    const [activeTab, setActiveTab] = useState(0)
    const {feeRebate, setToDate, setFromDate, handleCalendarDatesChange} = useUserInfoContext()

    useEffect(() => {
        handleDateChange(activeTab, setFromDate, setToDate)
    }, [activeTab, setFromDate, setToDate])

    return (
        <div className="main-page-info-card-bg main-page-info-fee-rebate-card">
            <p className="card-text-small">Fees Rebate</p>
            <div className="fee-rebate-card-date-picker">
                <DatePickerComponent activeTab={activeTab} setActiveTab={setActiveTab} handleCalendarDatesChange={handleCalendarDatesChange}/>
            </div>
            {!!feeRebate.length ? <FeesInfo/> : <EmptyFeeState/>}

        </div>
    );
};


