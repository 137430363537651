import React from 'react';
import {TransactionsFeesCard} from "./TransactionsFeesCard";
import {FeeRebateCard} from "./FeeRebateCard";
import {ChartCard} from "./ChartCard";


export const InfoSection = () => {
    return (
        <div className="main-page-info-container">
            <div className="main-page-info-left">
                <TransactionsFeesCard />
                <FeeRebateCard/>
            </div>
            <div className="main-page-info-right">
                <ChartCard/>
            </div>
        </div>
    );
};