import React, {useState} from "react";
import ArrowLeft from "../../../../Assets/mainPage/calendar-arrow-left.svg";
import {monthsNames} from "../../../../constants/calendar";
import ArrowRight from "../../../../Assets/mainPage/calendar-arrow-right.svg";
import {getNumberOfDaysInMonth, getSortedDays, range} from "../../../../utils/calendar-utils";

interface Props {
    selectedFromDate: Date | null
    setSelectedFromDate: (date: Date) => void
    selectedToDate: Date | null
    setSelectedToDate: (date: Date) => void
    isFrom: boolean | null
    onReset: () => void
    onSave: () => void
    relative?: boolean
    minDate: Date
    maxDate: Date
}

export const Calendar = ({setSelectedFromDate, selectedFromDate, selectedToDate, setSelectedToDate, isFrom, onReset,onSave, relative = false, minDate, maxDate}: Props) => {
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth())
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear())

    const nextMonth = () => {
        if(currentMonth < 11) {
            setCurrentMonth(prev => prev + 1)
        } else {
            setCurrentMonth(0)
            setCurrentYear(prev => prev + 1)
        }
    }

    const prevMonth = () => {
        if(currentMonth > 0) {
            setCurrentMonth(prev => prev - 1)
        } else {
            setCurrentMonth(11)
            setCurrentYear(prev => prev - 1)
        }
    }

    const handleFromDateSelect = (date: Date) => {
        if(selectedToDate) {
            const timeDifference = date.getTime() - selectedToDate?.getTime()
            if(timeDifference < 0) {
                setSelectedFromDate(date)
            } else {
                setSelectedFromDate(date)
                setSelectedToDate(date)
            }
        } else {
            setSelectedFromDate(date)
        }
    }

    const handleToDateSelect = (date: Date) => {
        if(selectedFromDate) {
            const timeDifference = date.getTime() - selectedFromDate?.getTime()
            if(timeDifference > 0) {
                setSelectedToDate(date)
            } else {
                setSelectedToDate(date)
                setSelectedFromDate(date)
            }
        } else {
            setSelectedToDate(date)
        }
    }


    const handleSelection = (event: any) => {
        const dataDay = event.target.getAttribute('data-day')
        if(!dataDay) return

        const date = new Date(currentYear, currentMonth, dataDay)

        if(isFrom) {
            handleFromDateSelect(date)
        } else {
            handleToDateSelect(date)
        }
    }

    const getTimes = (day: number) => {
        const createdTime =  new Date(currentYear, currentMonth, day).getTime()
        const selectedFromTime = selectedFromDate?.getTime()
        const selectedToTime =  selectedToDate?.getTime()
        return {
            createdTime, selectedToTime, selectedFromTime
        }
    }

    const getActiveClassName = (day: number) => {
       const {createdTime, selectedToTime, selectedFromTime} = getTimes(day)

        const isFromActive = selectedFromTime === createdTime
        const isToActive = selectedToTime === createdTime

        if(isFromActive && isToActive) {
            return 'day-is-active-left day-is-active-right'
        }

        if(isFromActive) {
            return 'day-is-active-left'
        }
        if(isToActive) {
            return 'day-is-active-right'
        }
    }

    const getRangeActiveClass = (day: number) => {
        if(!selectedToDate || !selectedToDate) {
             return ''
        }
        const {createdTime, selectedToTime, selectedFromTime} = getTimes(day)

        // @ts-ignore
        const isActive = createdTime > selectedFromTime && createdTime < selectedToTime

        if(isActive) {
            return 'day-active-range'
        }
    }

    const getDisabledClas = (day: number) => {
        const current = new Date(currentYear, currentMonth, day)
        const isValidDay = minDate.getTime() < current.getTime() && maxDate.getTime() > current.getTime()
        return !isValidDay ? 'day-disabled' : ''
    }


    return (
        <div onClick={e => e.stopPropagation()} className="calendar-window border-mask" style={relative ? {position: 'relative', top: 0} : {}}>
            <div className="calendar-months-row">
                <img onClick={prevMonth} className="calendar-arrow" src={ArrowLeft} alt=''/>
                <div className="calendar-month-name">
                    {`${monthsNames[currentMonth]} ${currentYear}`}
                </div>
                <img onClick={nextMonth} className="calendar-arrow" src={ArrowRight} alt=''/>
            </div>
            <div className="calendar-days-container">
                <div className="days-row">
                    {getSortedDays(currentYear, currentMonth).map(day  => <div className="calendar-text-item days-color" key={day}>{day}</div>)}
                </div>
                <div onClick={handleSelection} className="days-row">
                    {range(
                        1,
                        getNumberOfDaysInMonth(currentYear, currentMonth) + 1
                    ).map(day => (
                        <div
                            data-day={day}
                            className={`${getActiveClassName(day)} ${getRangeActiveClass(day)} ${getDisabledClas(day)} calendar-text-item color-white pointer`}
                            key={day}>
                        {day}
                    </div>
                    ))}
                </div>
            </div>
            <div className="calendar-buttons">
                <button onClick={onSave} className="main-page-button btn calendar-button-width">Save</button>
                <div onClick={onReset} className="calendar-month-name reset-button">Reset</div>
            </div>
        </div>
    )
}