export const handleDateChange = (activeTab: number, setFromDate: (date: string) => void, setToDate: (date: string) => void) => {
    if(activeTab === 0) {
        const date = new Date()
        const startDate = new Date(date.setHours(0, 0, 0, 0)).toISOString()
        setFromDate(startDate)
        setToDate(new Date().toISOString())
    }
    if(activeTab === 1) {
        const date = new Date()
        const startDate = new Date(date.getFullYear(), date.getMonth() - 1, 1).toISOString()
        const endDate = new Date(date.getFullYear(), date.getMonth() , 1).toISOString()
        setFromDate(startDate)
        setToDate(endDate)
    }
    if(activeTab === 2) {
        setFromDate('')
        setToDate('')
    }
}