export const getChartWidth = (windowWidth: number) => {
    let width = 639
    if(windowWidth < 1230) {
        width = 600
    }
    if(windowWidth < 1190) {
        width = 540
    }
    if(windowWidth < 1130) {
        width = 520
    }
    if(windowWidth < 1100) {
        width = 500
    }
    if(windowWidth < 1050) {
        width = 465
    }
    if(windowWidth < 1014) {
        width = 420
    }
    if(windowWidth < 980) {
        width = 400
    }
    if(windowWidth < 920) {
        width = 370
    }
    if(windowWidth < 880) {
        width = 760
    }
    if(windowWidth < 850) {
        width = 730
    }
    if(windowWidth < 818) {
        width = 700
    }
    if(windowWidth < 775) {
        width = 670
    }
    if(windowWidth < 740) {
        width = 640
    }
    if(windowWidth < 715) {
        width = 600
    }
    if(windowWidth < 675) {
        width = 560
    }
    if(windowWidth < 635) {
        width = 500
    }
    if(windowWidth < 570) {
        width = 450
    }
    if(windowWidth < 520) {
        width = 400
    }
    if(windowWidth < 475) {
        width = 350
    }
    if(windowWidth < 420) {
        width = 310
    }
    if(windowWidth < 380) {
        width = 290
    }
    return width
}

export const getChartHeight = (windowWidth: number) => {
    let height = 350
    if(windowWidth < 580) {
        height = 300
    }
    return height
}

export const getChartSizes = () => {
    const windowWidth = window.innerWidth
    const width = getChartWidth(windowWidth)
    const height = getChartHeight(windowWidth)
    return {width, height}
}