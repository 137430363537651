import {MutableRefObject, useEffect, useRef, useState} from "react";
import {getChartSizes} from "../utils/getChartWidth";
import {createChart, IChartApi} from "lightweight-charts";
import {chartOptions, setAreaOptions} from "../constants/chart-config";
import {calculateTooltipTopPosition} from "../utils/chart-utils";
import {IChartData, TooltipInfo} from "../types/dashboard";

const toolTipWidth = 204

interface DataType {
    value: number
    time: number
}

export function useChart(container: MutableRefObject<HTMLDivElement | null>, formattedChartInfo: IChartData[]) {
    const chart = useRef<IChartApi>()
    const [tooltipVisible, setTooltipVisible] = useState(false)
    const [left, setLeft] = useState(0)
    const [top, setTop] = useState(0)
    const [tooltipInfo, setTooltipInfo] = useState<TooltipInfo>({} as TooltipInfo)

    useEffect(() => {
        if (container.current) {
            if(chart.current) {
                chart.current?.remove()
            }
            const {width, height} = getChartSizes()
            chart.current = createChart(container.current, chartOptions);
            chart.current.resize(width, height);
            const lineSeries = chart.current.addAreaSeries(setAreaOptions());
            lineSeries.setData(formattedChartInfo);
            chart.current.timeScale().fitContent();

            chart.current.subscribeCrosshairMove(param => {
                if(!container?.current) return
                if (
                    param.point === undefined ||
                    !param.time ||
                    param.point.x < 0 ||
                    param.point.x > container?.current?.clientWidth ||
                    param.point.y < 0 ||
                    param.point.y > container?.current?.clientHeight
                ) {
                    setTooltipVisible(false)
                } else {
                    setTooltipVisible(true)
                    const data:DataType | undefined = param.seriesData.get(lineSeries) as DataType | undefined;
                    const datapoint = formattedChartInfo.find(point => point.time === param.time);
                    if(datapoint) {
                        setTooltipInfo(datapoint)
                    }
                    let defaultShift = param.point.x - 50;
                    const shiftedCoordinate = Math.max(
                        0,
                        Math.min(container.current.clientWidth - toolTipWidth, defaultShift)
                    );

                    if(data && data?.value !== undefined) {
                        const price = data.value;
                        const coordinate = lineSeries.priceToCoordinate(price);
                        if(coordinate) {
                            const {coordinateY, additionalPixels} = calculateTooltipTopPosition(container.current, coordinate)
                            setLeft(shiftedCoordinate)
                            setTop(coordinateY + additionalPixels)
                        }
                    }

                }
            })
        }
    }, [formattedChartInfo, container])

    return {
        tooltipVisible,
        tooltipInfo,
        top,
        left
    }
}