import React from 'react';
import {useUserStore} from "../../../store/useUserStore";

export const MainPageHeadings = () => {
    const {user} = useUserStore()
    return (
        <div className="main-page-heading-container">
            <h2 className='main-page-heading main-page-headings-common'>#12345 API Statistic</h2>
            <h6 className='main-page-heading-small main-page-headings-common'>{user?.username}</h6>
        </div>
    );
};
