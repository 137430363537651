import React, {createContext, ReactNode, useCallback, useContext, useEffect, useState} from 'react'
import {useAuth} from "../hooks/useAuth";
import {useMetrics} from "../hooks/useMetrics";
import {IChartData, ITokenInfo} from "../types/dashboard";

interface InitialType  {
    totalTransactions: number
    totalFees: number,
    feeRebate: ITokenInfo[],
    chartInfo: IChartData[],
    setFromDate: (date: string) => void,
    setToDate: (date: string) => void,
    setFromDateChart: (date: string) => void,
    setToDateChart: (date: string) => void,
    handleCalendarDatesChange: (dateFrom: string, dateTo: string) => void,
    handleCalendarDatesChangeChart: (dateFrom: string, dateTo: string) => void
}

const InitialContextValues:InitialType = {
    totalTransactions: 0,
    totalFees: 0,
    feeRebate: [],
    chartInfo:[],
    setFromDate: (date: string) => {},
    setToDate: (date: string) => {},
    setFromDateChart: (date: string) => {},
    setToDateChart: (date: string) => {},
    handleCalendarDatesChange: (dateFrom: string, dateTo: string) => {},
    handleCalendarDatesChangeChart: (dateFrom: string, dateTo: string) => {}
}

export const UserInfoContext = createContext(InitialContextValues)

export const useUserInfoContext = () => useContext(UserInfoContext)

export const UserInfoProvider = ({ children }: { children: ReactNode}) => {
    const {refresh} = useAuth()
    const {getTotalInfo, getFeeRebate, getChartInfo} = useMetrics()
    const [totalTransactions, setTotalTransactions] = useState(0)
    const [totalFees, setTotalFees] = useState(0)
    const [token, setToken] = useState('')
    const [feeRebate, setFeeRebate] = useState([])
    const [chartInfo, setChartInfo] = useState([])
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const [fromDateChart, setFromDateChart] = useState('')
    const [toDateChart, setToDateChart] = useState('')

    const handleRefresh = async () => {
        const newToken = await refresh()
        if(newToken){
            setToken(newToken)
        }
    }

    const getTotalData = async (newToken: string) => {
        const data = await getTotalInfo(newToken)
        if(data?.transactions !== undefined) {
            setTotalTransactions(data?.transactions)
        }
        if(data?.fees !== null && data?.fees !== undefined) {
            setTotalFees(data?.fees)
        }
    }

    const handleFeeRebate = useCallback(async () => {
        if(!token) return
        const data = await getFeeRebate(token, fromDate, toDate)
        if(data !== undefined) {
            setFeeRebate(data)
        }
    }, [token, fromDate, toDate])

    const handleChart = useCallback(async () => {
        if(!token) return
        const data = await getChartInfo(token, fromDateChart, toDateChart)
        if(data !== undefined) {
            setChartInfo(data)
        }
    }, [token, fromDateChart, toDateChart])

    const handleCalendarDatesChange = (dateFrom:string, dateTo:string) => {
        if(!dateFrom || !dateTo) return
        setToDate(dateTo)
        setFromDate(dateFrom)
    }

    const handleCalendarDatesChangeChart = (dateFrom:string, dateTo:string) => {
        if(!dateFrom || !dateTo) return
        setToDateChart(dateTo)
        setFromDateChart(dateFrom)
    }

    useEffect(() => {
        handleRefresh()
    }, [])

    useEffect(() => {
        if(!token) return
        getTotalData(token)
    }, [token])

    useEffect(() => {
        handleFeeRebate()
    }, [handleFeeRebate])

    useEffect(() => {
        handleChart()
    }, [handleChart])


    const value = {
        totalTransactions,
        totalFees,
        feeRebate,
        chartInfo,
        setFromDate,
        setToDate,
        setFromDateChart,
        setToDateChart,
        handleCalendarDatesChange,
        handleCalendarDatesChangeChart
    }

    return (
        <UserInfoContext.Provider value={value}>
            {children}
        </UserInfoContext.Provider>
    )
}
