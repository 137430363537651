import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './App.css';

import { AppRouter } from './router';

export default function App() {
  return (
    <BrowserRouter>
      <ToastContainer className="toast-position" />
      <AppRouter />
    </BrowserRouter>
  );
}
