import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import gitbook from '../Assets/Icons/gitbook.svg';
import github from '../Assets/Icons/github.svg';
import linkedin from '../Assets/Icons/linkedin.svg';
import medium from '../Assets/Icons/medium.svg';
import RetroBridgeSvg from '../Assets/Icons/retrobridge.svg';
import TermsPrivacySvg from '../Assets/Icons/terms-privacy.svg';
import twitter from '../Assets/Icons/twitter.svg';
import { LINK_TO_PRIVACY_TERMS } from '../constants/links';
import './styles.css';

const Footer = () => {
  return (
    <Container
      fluid
      className="d-flex flex-wrap-reverse  align-items-center footer-mt footer-container-padding"
    >
      <Col
        className="footer-text-display text-white fw-lighter footer-left-padding"
        style={{ fontSize: '12px' }}
        xs={3}
      >
        <img src={RetroBridgeSvg} width="105px" height="20px" alt="" />

        <Link to={LINK_TO_PRIVACY_TERMS} target="_blank">
          <img
            className="terms-privacy-text"
            src={TermsPrivacySvg}
            width="83px"
            height="20px"
            alt="Terms & Privacy"
          />
        </Link>
      </Col>

      <Col xs={9} className="footer-icons-display justify-content-end ">
        <Button
          onClick={() => window.open('https://docs.retrobridge.io/', '_blank')}
          variant="link"
          className="me-3 footerBtn"
        >
          <img
            style={{ width: '14px', height: '14px' }}
            src={gitbook}
            alt="logo"
          />
        </Button>
        <Button
          onClick={() =>
            window.open('https://github.com/retro-bridge', '_blank')
          }
          variant="link"
          className="me-3 footerBtn"
        >
          <img
            style={{ width: '14px', height: '14px' }}
            src={github}
            alt="logo"
          />
        </Button>
        <Button
          onClick={() =>
            window.open('https://retro-bridge.medium.com', '_blank')
          }
          variant="link"
          className="me-3 footerBtn"
        >
          <img
            style={{ width: '14px', height: '14px' }}
            src={medium}
            alt="logo"
          />
        </Button>
        <Button
          variant="link"
          className="me-3 footerBtn"
          onClick={() =>
            window.open('https://twitter.com/retro_bridge', '_blank')
          }
        >
          <img
            style={{ width: '14px', height: '14px' }}
            src={twitter}
            alt="logo"
          />
        </Button>
        <Button
          variant="link"
          className="me-3 footerBtn"
          onClick={() =>
            window.open(
              'https://www.linkedin.com/company/retrobridge/',
              '_blank'
            )
          }
        >
          <img
            style={{ width: '14px', height: '14px' }}
            src={linkedin}
            alt="logo"
          />
        </Button>
      </Col>
    </Container>
  );
};

export default Footer;
