import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import CloseBurgerMenuIcon from '../Assets/Icons/close-burger-menu.svg';
import gitbook from '../Assets/Icons/gitbook.svg';
import github from '../Assets/Icons/github.svg';
import linkedin from '../Assets/Icons/linkedin.svg';
import medium from '../Assets/Icons/medium.svg';
import retroIcon from '../Assets/Icons/retroIcon.svg';
import twitter from '../Assets/Icons/twitter.svg';
import MobileMenuBg from '../Assets/images/mobile-menu-bg.svg';
import LogoutIcon from '../Assets/mainPage/logout-icon.svg';
import { LINK_TO_API_DOCS } from '../constants/links';
import { HOME_PAGE } from '../constants/routes.constants';
import { useAuth } from '../hooks/useAuth';

interface Props {
  closeMenu: () => void;
}
const MobileMenu = ({ closeMenu }: Props) => {
  const { logOut } = useAuth();
  const { pathname } = useLocation();
  const showLogout = pathname === HOME_PAGE;
  return (
    <div className="mobile-menu-container">
      <div className="mobile-menu-background">
        <img src={MobileMenuBg} alt="" className="mobile-menu-img" />
        <div className="mobile-menu-content">
          <div className="d-md-none d-flex justify-content-between align-items-center w-100 menu-header-ml">
            <div onClick={closeMenu}>
              <img
                width="24px"
                height="24px"
                src={CloseBurgerMenuIcon}
                alt=""
              />
            </div>
            <Link to={HOME_PAGE}>
              <img
                src={retroIcon}
                alt="logo"
                style={{
                  width: '170px',
                  height: '40px',
                  marginLeft: '46px',
                  marginTop: '-6px',
                }}
              />
            </Link>
            <div style={{ width: '79px', height: '50px' }} />
          </div>
          <div className="mobile-menu-body">
            <div className="mobile-menu-tabs">
              <Link
                to={LINK_TO_API_DOCS}
                target={'_blank'}
                className="mobile-menu-tabs-item"
              >
                API docs
              </Link>
              {showLogout && (
                <span
                  className="mobile-menu-tabs-item logout-button"
                  onClick={logOut}
                >
                  <img src={LogoutIcon} className="logout-icon" alt="" />
                  Logout
                </span>
              )}
            </div>
            <div className="mobile-menu-footer">
              <div className="mobile-menu-footer-btn">
                <img
                  onClick={() =>
                    window.open('https://docs.retrobridge.io/', '_blank')
                  }
                  src={gitbook}
                  alt="logo"
                />
              </div>
              <div className="mobile-menu-footer-btn">
                <img
                  onClick={() =>
                    window.open('https://github.com/retro-bridge', '_blank')
                  }
                  src={github}
                  alt="logo"
                />
              </div>
              <div className="mobile-menu-footer-btn">
                <img
                  onClick={() =>
                    window.open('https://retro-bridge.medium.com', '_blank')
                  }
                  src={medium}
                  alt="logo"
                />
              </div>
              <div className="mobile-menu-footer-btn">
                <img
                  onClick={() =>
                    window.open('https://twitter.com/retro_bridge', '_blank')
                  }
                  src={twitter}
                  alt="logo"
                />
              </div>
              <div className="mobile-menu-footer-btn">
                <img
                  onClick={() =>
                    window.open(
                      'https://www.linkedin.com/company/retrobridge/',
                      '_blank'
                    )
                  }
                  src={linkedin}
                  alt="logo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
