import React, {useCallback, useEffect, useRef, useState} from 'react';
import CalendarLogo from '../../../../Assets/mainPage/calendar.svg'
import './calendar.css'
import {useOnClickOutside} from "../../../../hooks/useOnClickOutside";
import {Calendar} from "./Calendar";
import {getDisplayedDate} from "../../../../utils/calendar-utils";
import {Modal} from "../../../../components/Modal";

interface PropsCalendar {
    handleCalendarDatesChange:(dateFrom: string, dateTo: string) => void
}
interface Props {
    title: string
    date: string
    onClick: () => void
    isActive: boolean
}

export const Calendars = ({handleCalendarDatesChange}:PropsCalendar) => {
    const node = useRef<HTMLDivElement>(null)
    const [isOpen, setIsOpen] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [selectedFromDate, setSelectedFromDate] = useState<Date | null>(null)
    const [selectedToDate, setSelectedToDate] = useState<Date | null>(null)
    const [isFrom, setIsFrom] = useState<boolean | null>(null)
    const [isSmallWindow, setIsSmallWindow] = useState(false)

    const toggle = () => {
        if(isSmallWindow) {
            setIsModalOpen(prev => !prev)
        } else {
            setIsOpen(prev => !prev)
        }
    }
    const closeModal = () => {
        setIsModalOpen(false)
        setIsFrom(null)
    }

    const setFrom = () => {
        setIsFrom(true)
        if(isSmallWindow) {
            setIsModalOpen(true)
        } else {
            setIsOpen(true)
        }
    }
    const setTo = () => {
        setIsFrom(false)
        if(isSmallWindow) {
            setIsModalOpen(true)
        } else {
            setIsOpen(true)
        }
    }

    const handleCalendarClose = useCallback(() =>{
        if(isSmallWindow) {
            setIsModalOpen(false)
        } else {
            setIsOpen(false)
        }
        setIsFrom(null)
    }, [isSmallWindow])

    const onSave = useCallback(() => {
        const dateFrom = selectedFromDate?.toISOString()
        const dateTo = selectedToDate?.toISOString()
        if(!dateFrom || !dateTo) {
            handleCalendarClose()
            return
        }
        handleCalendarDatesChange(dateFrom, dateTo)
        handleCalendarClose()
    }, [selectedFromDate, selectedToDate, handleCalendarDatesChange, handleCalendarClose])

    const onReset = () => {
        setSelectedFromDate(null)
        setSelectedToDate(null)
    }

    const handleClickOutside = () => {
        setIsFrom(null)
        toggle()
    }

    const handleSelectFrom = (date: Date) => {
        setSelectedFromDate(date)
        if(selectedToDate === null) {
            setIsFrom(false)
        }
    }

    const handleSelectTo = (date: Date) => {
        setSelectedToDate(date)
        if(selectedFromDate === null) {
            setIsFrom(true)
        }
    }

    useOnClickOutside(node, isOpen ? handleClickOutside : undefined)

    const dateFrom = selectedFromDate === null ? '-' : getDisplayedDate(selectedFromDate)
    const dateTo = selectedToDate === null ? '-' : getDisplayedDate(selectedToDate)

    const minDate = new Date(2023, 8, 12)

    useEffect(() => {
        const windowWidth = window.innerWidth
        if(windowWidth < 881) {
            setIsSmallWindow(true)
        }
    }, [])

    return (
        <div ref={node as any} className="card-calendars-container mt-16">
            <DateDisplay date={dateFrom} onClick={setFrom} title="From" isActive={isFrom === true}/>
            <DateDisplay date={dateTo} onClick={setTo} title="To" isActive={isFrom === false}/>
            {isOpen && <Calendar
                selectedFromDate={selectedFromDate}
                setSelectedFromDate={handleSelectFrom}
                selectedToDate={selectedToDate}
                setSelectedToDate={handleSelectTo}
                isFrom={isFrom}
                onReset={onReset}
                onSave={onSave}
                minDate={minDate}
                maxDate={new Date()}
            />}
            {isModalOpen && (
                <Modal isOpen={isModalOpen} closeModal={closeModal}>
                    <div className="calendar-modal-container">
                        <Calendar
                            selectedFromDate={selectedFromDate}
                            setSelectedFromDate={handleSelectFrom}
                            selectedToDate={selectedToDate}
                            setSelectedToDate={handleSelectTo}
                            isFrom={isFrom}
                            onReset={onReset}
                            onSave={onSave}
                            relative={true}
                            minDate={minDate}
                            maxDate={new Date()}
                        />
                    </div>
                </Modal>
            )}
        </div>
    );
};

const DateDisplay = ({onClick, date, title, isActive}:Props) =>{
    return (
        <div onClick={onClick} className={`card-calendar ${isActive && 'calendar-active'}`} >
            <div className="calendar-text-container">
                <p className="calendar-text-small">{title}</p>
                <p className="calendar-text-big">{date}</p>
            </div>
            <div className="calendar-icon-container">
                <img className="calendar-icon" src={CalendarLogo} alt=""/>
            </div>
        </div>
    )
}

