import { Buffer } from 'buffer';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './Assets/fonts/satoshi/css/satoshi.css';
import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
window.Buffer = window.Buffer || Buffer;

root.render(
  <React.StrictMode>
      <div id="portal" />
      <App />
  </React.StrictMode>
);