import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import { Link, useLocation } from 'react-router-dom';
import BurgerMenuIcon from '../Assets/Icons/burger-menu.svg';
import retroIcon from '../Assets/Icons/retroIcon.svg';
import LogoutIcon from '../Assets/mainPage/logout-icon.svg';
import { LINK_TO_API_DOCS } from '../constants/links';
import { HOME_PAGE } from '../constants/routes.constants';
import { useAuth } from '../hooks/useAuth';
import MobileMenu from './MobileMenu';

const Header = () => {
  const { logOut } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { pathname } = useLocation();
  const showLogout = pathname === HOME_PAGE;

  const openMobileMenu = () => {
    setIsMenuOpen(true);
  };

  const closeMobileMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <Container
      fluid
      className={`d-flex flex-column align-items-center header-ml top-0 z-3 w-100 position-relative header-mt`}
    >
      {isMenuOpen && <MobileMenu closeMenu={closeMobileMenu} />}
      <div className="d-md-none d-flex justify-content-between align-items-center w-100">
        <div onClick={openMobileMenu}>
          <img width="24px" height="24px" src={BurgerMenuIcon} alt="" />
        </div>
        <Link to="https://retrobridge.io/" style={{ marginLeft: '16px' }}>
          <img
            src={retroIcon}
            alt="logo"
            style={{ width: '170px', height: '40px' }}
          />
        </Link>
        <div style={{ width: '40px' }}></div>
      </div>

      <div className="flex-row justify-content-md-between  justify-content-end w-100 spacing-left-2 header-tabs">
        <Link to="https://retrobridge.io/">
          <img
            className=" d-none d-md-block"
            src={retroIcon}
            alt="logo"
            style={{ width: '160px', height: '40 px' }}
          />
        </Link>
        <div className="d-flex">
          <div
            className={`noRoundRight headerRight   pe-xs-0  header-pl justify-content-start justify-content-between  flex-wrap  flex-lg-nowrap rounded-5 `}
            style={{ paddingRight: '56px', display: 'flex' }}
          >
            <Link
              to={LINK_TO_API_DOCS}
              target="_blank"
              className=" text-white text-decoration-none   header-button"
            >
              API docs
            </Link>
            {showLogout && (
              <button
                className="text-white text-decoration-none text-nowrap header-button logout-button"
                onClick={logOut}
              >
                <img src={LogoutIcon} className="logout-icon" alt="" />
                <span>Logout</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Header;
