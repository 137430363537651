import React from 'react';
import { Helmet } from 'react-helmet';
import './MainPage.css';
import Header from "../../components/header";
import imageTop from "../../Assets/images/topBg.png";
import Footer from "../../components/footer";
import {MainPageHeadings} from "./components/Headings";
import {ClaimSection} from "./components/ClaimSection";
import {InfoSection} from "./components/InfoSection";
import {UserInfoProvider} from "../../context/UserInfoContext";

export const MainPage = () => {

  return (
      <UserInfoProvider>
          <Helmet>
              <title>RetroBridge | Dashboard</title>
          </Helmet>
          <section className='main-page-body'>
              <div style={{zIndex: 999}}>
                <Header />
              </div>
              <img
                  src={imageTop}
                  alt=''
                  className='main-page-gradient'
              />
              <div className='main-page-content'>
                  <MainPageHeadings/>
                  <InfoSection />
                  <ClaimSection/>
              </div>
              <div className="main-page-footer">
                  <Footer />
              </div>
          </section >
      </UserInfoProvider>
  )
}

