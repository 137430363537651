import React, { FC } from 'react'
import { createPortal } from 'react-dom'

interface IProps {
    isOpen: boolean
    closeModal(): void
    children: React.ReactNode
}

export const Modal: FC<IProps> = ({ isOpen, closeModal, children }) => {
    const container = document.getElementById('portal')

    if (!isOpen) return null
    return container
        ? createPortal(
            <div
                className="modal-wrapper"
                onClick={closeModal}
            >
                {children}
            </div>,
            container
        )
        : null
}
