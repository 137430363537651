import React from 'react';
import { BASE_URL } from '../../../Config/ApiConfig';
import { useUserInfoContext } from '../../../context/UserInfoContext';
import { TokenRowProps } from '../../../types/dashboard';
import { getLogo, toFixedAmount } from '../../../utils/dashboard';

export const FeesInfo = () => {
  const { feeRebate } = useUserInfoContext();
  return (
    <div className="fees-info">
      {!!feeRebate?.length &&
        feeRebate.map((data, index) => (
          <TokenInfoRow
            key={index.toString()}
            value={data.fee_amount}
            currency={data.token_symbol}
            logo={data.image_url}
          />
        ))}
    </div>
  );
};

const TokenInfoRow = ({ value, currency, logo }: TokenRowProps) => {
  const amount = toFixedAmount(value, 4);
  return (
    <div className="fees-info-row">
      <img className="fees-info-icon" src={`${BASE_URL}${logo}`} alt="" />
      <div className="fees-info-text">
        {amount} {currency}
      </div>
    </div>
  );
};
