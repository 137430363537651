import React from 'react';
import EmptyChartImg from '../../../Assets/mainPage/empty-chart.png'
import EmptyChartBgImg from '../../../Assets/mainPage/empty-chart-state-background.png'

export const EmptyChartState = () => {
    return (
        <div className="empty-chart-state">
            <div className="empty-chart-state-img">
                <img className="empty-chart-state-bg-img" src={EmptyChartBgImg} alt=""/>
                <img className="empty-chart-state-diagram-img" src={EmptyChartImg} alt=""/>
            </div>
            <div className="empty-chart-state-text">
                Here you will see the transaction chart over the selected period of time
            </div>
        </div>
    );
};
