import React from 'react';
import { BASE_URL } from '../../../Config/ApiConfig';
import { TokenRowProps, TooltipInfo } from '../../../types/dashboard';
import { getDisplayedDate } from '../../../utils/calendar-utils';
import { getLogo, toFixedAmount } from '../../../utils/dashboard';

interface Props {
  tooltipVisible: boolean;
  left: number;
  top?: number;
  info: TooltipInfo;
}

export const Tooltip = ({ tooltipVisible, left, top, info }: Props) => {
  const date = new Date(info?.time * 1000);
  const displayedDate = getDisplayedDate(date);

  if (!tooltipVisible) return null;

  return (
    <div
      className="chart-tooltip border-mask"
      style={{
        left: left + 'px',
        top: top + 'px',
      }}
    >
      <p className="card-tooltip-text color-white">{displayedDate}</p>
      <div className="card-tooltip-row-between">
        <p className="card-tooltip-text color-secondary">Transactions Count</p>
        <p className="card-tooltip-text color-white">{info?.value}</p>
      </div>
      <p className="card-tooltip-text color-secondary">Fees Earned:</p>
      <div className="card-tooltip-fee-info">
        {info?.tokens?.length !== 0 &&
          info?.tokens?.map((token, index) => (
            <TooltipTokenRow
              key={index.toString()}
              currency={token.token_symbol}
              value={token.fee_amount}
              logo={token.image_url}
            />
          ))}
      </div>
    </div>
  );
};

const TooltipTokenRow = ({ currency, value, logo }: TokenRowProps) => {
  const amount = toFixedAmount(value, 4);
  return (
    <div className="card-tooltip-fee-info-block">
      <img className="card-tooltip-icon" src={`${BASE_URL}${logo}`} alt="" />
      <p className="card-tooltip-text color-white">
        {amount} {currency}
      </p>
    </div>
  );
};
