import {LoginPage} from "../pages/LoginPage";
import {MainPage} from "../pages/Main";
import {HOME_PAGE, LOGIN_PAGE} from "../constants/routes.constants";

export const ROUTES = {
    login: LOGIN_PAGE,
    home: HOME_PAGE,
}

export const routes = {
    public: [
        { path: ROUTES.login, Element: LoginPage },
    ],
    private: [
        { path: ROUTES.home, Element: MainPage },

    ],
}
