import create from 'zustand'

interface UserStoreState {
    token: string,
    setToken: (token: string) => void
}

export const useTokenStore = create<UserStoreState>()(
        (set) => ({
            token: '',
            setToken: (token: string) =>
                set({ token }),
        })
    )