import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { PrivateRoute } from './components/PrivateRoute'
import { PublicRoute } from './components/PublicRoute'
import {routes} from "./routes";
import {LOGIN_PAGE} from "../constants/routes.constants";

export const AppRouter = () => {
    return (
        <Routes>
            {routes.public.map(({ path, Element }) => (
                <Route
                    key={path}
                    path={path}
                    element={
                        <PublicRoute>
                            <Element />
                        </PublicRoute>
                    }
                />
            ))}
            {routes.private.map(({ path, Element }) => (
                <Route
                    key={path}
                    path={path}
                    element={
                        <PrivateRoute>
                            <Element />
                        </PrivateRoute>
                    }
                />
            ))}
            <Route path="*" element={<Navigate to={LOGIN_PAGE} />} />
        </Routes>
    )
}
