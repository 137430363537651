import { useCallback, useState } from 'react';
import { apiUrls } from '../constants/urls';
import { client } from '../service/client';
import { useTokenStore } from '../store/useTokenStore';
import { useUserStore } from '../store/useUserStore';
import { CustomError, LoginData } from '../types/auth';
import { notify } from '../utils/toast';

const INVALID_CREDENTIALS = 'Invalid credentials';

export function useAuth() {
  const [invalid, setInvalid] = useState<boolean>(false);

  const { setUser, unsetUser } = useUserStore();
  const { setToken } = useTokenStore();

  const login = async (loginData: LoginData) => {
    try {
      const { data } = await client.post(apiUrls.login, loginData);
      setUser(data?.user);
      setToken(data?.tokens?.access);
    } catch (err) {
      if (
        (err as CustomError).response?.data?.message === INVALID_CREDENTIALS
      ) {
        notify(INVALID_CREDENTIALS);
        setInvalid(true);
      }
      console.error(err);
    }
  };

  const logOut = async () => {
    try {
      await client.post(apiUrls.logout);
    } catch (err) {
      console.error(err);
    } finally {
      unsetUser();
      setToken('');
    }
  };

  const refresh = useCallback(async () => {
    try {
      const { data } = await client.get(apiUrls.refresh);
      setUser(data?.user);
      setToken(data?.tokens?.access);
      return data?.tokens?.access;
    } catch (err) {
      unsetUser();
      console.error(err);
    }
  }, [setUser, setToken, unsetUser]);

  return {
    login,
    logOut,
    refresh,
    invalid,
    setInvalid,
  };
}
