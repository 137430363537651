import React  from 'react'
import { Navigate } from 'react-router-dom'
import { shallow } from 'zustand/shallow'
import { useUserStore } from '../../store/useUserStore'
import {HOME_PAGE} from "../../constants/routes.constants";

export const PublicRoute = ({ children }: {children: React.ReactElement}) => {
    const { user } = useUserStore(({ user }) => ({ user }), shallow)

    return user !== null ? <Navigate to={HOME_PAGE} /> : children
}
