import create from 'zustand'
import { persist } from 'zustand/middleware'

interface UserState {
    id: string
    username: string
    fee_rebate?: number
}
interface UserStoreState {
    user: UserState | null,
    setUser: (userInfo: UserState | null) => void
    unsetUser: () => void
}

export const useUserStore = create<UserStoreState>()(
    persist(
        (set) => ({
            user: null,
            setUser: (userInfo: UserState | null) =>
                set({
                    user: userInfo,
                }),
            unsetUser: () => set({ user: null }),
        }),
        {
            name: 'user-storage',
        }
    )
)