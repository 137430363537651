import React from 'react';
import {Calendars} from "./calendar/Calendars";

interface Props  {
    activeTab: number
    setActiveTab: (val: number) => void
    handleCalendarDatesChange:(dateFrom: string, dateTo: string) => void
}

export const DatePickerComponent = ({activeTab, setActiveTab, handleCalendarDatesChange}: Props) => {

    const first = activeTab === 0
    const second = activeTab === 1
    const third = activeTab === 2
    const fourth = activeTab === 3

    return (
        <div className="mt-16">
            <div className="card-tabs">
                <div
                    className={`card-tab ${first && 'card-tab-active'}`}
                    onClick={() => setActiveTab(0)}
                >
                    today
                </div>
                <div
                    className={`card-tab ${second && 'card-tab-active'}`}
                    onClick={() => setActiveTab(1)}
                >
                    last month
                </div>
                <div
                    className={`card-tab ${third && 'card-tab-active'}`}
                    onClick={() => setActiveTab(2)}
                >
                    all time
                </div>
                <div
                    className={`card-tab ${fourth && 'card-tab-active'}`}
                    onClick={() => setActiveTab(3)}
                >
                    custom
                </div>
            </div>

            {fourth && <Calendars handleCalendarDatesChange={handleCalendarDatesChange}/>}
        </div>
    );
};
