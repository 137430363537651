import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../../components/footer';
import Header from '../../components/header';
import imageTop from '../../Assets/images/topBg.png';
import './LoginPage.css';
import { LoginForm } from './components/LoginForm';

export const LoginPage = () => {
  return (
    <>
      <Helmet>
        <title>RetroBridge | Developers API</title>
      </Helmet>

      <section className='loginBody'>
        <Header />

        <img
          src={imageTop}
          alt=''
          className='loginBgTop'
        />

        <div className='loginContent'>
          <h2 className='loginHeading'>Welcome to API Dashbaord</h2>

          <LoginForm />
        </div>

        <div className="loginFooter">
          <Footer />
        </div>
      </section >
    </>
  );
}