import React from 'react';
import { LINK_TO_API_SUPPORT } from '../../../constants/links';
import { useUserInfoContext } from '../../../context/UserInfoContext';
import { useUserStore } from '../../../store/useUserStore';

export const TransactionsFeesCard = () => {
  const { totalFees, totalTransactions } = useUserInfoContext();
  const { user } = useUserStore();

  const fee = user?.fee_rebate ? `$${user?.fee_rebate}` : '-';

  return (
    <div className="main-page-info-card-bg main-page-info-transactions-card">
      <div className="row-between">
        <div>
          <p className="card-text-small mobile-small-text">
            Total Transactions
          </p>
          <p className="card-text-big mobile-big-text">{totalTransactions}</p>
        </div>
        <div>
          <p className="card-text-small mobile-small-text">Total Rebates</p>
          <p className="card-text-big mobile-big-text">${totalFees}</p>
        </div>
      </div>
      <div className="card-bottom-text-container">
        <div className="card-bottom-text-wrapper">
          <p className="card-bottom-text-1 mobile-line-height-20">
            Additional fee applied to each transaction is
            <span className="card-bottom-text-1-span mobile-line-height-20">
              {' '}
              {fee}
            </span>
          </p>
          <p className="card-bottom-text-1 mobile-line-height-20">
            To change,
            <a
              href={LINK_TO_API_SUPPORT}
              target="_blank"
              className="link-gradient mobile-line-height-20"
              rel="noreferrer"
            >
              {' '}
              Contact Retro Bridge API Support
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
