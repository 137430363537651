import React, { useState } from 'react';
import eyeOff from '../../../../Assets/Icons/eye-off.svg';
import { LINK_TO_API_SUPPORT } from '../../../../constants/links';
import { useAuth } from '../../../../hooks/useAuth';
import { LoginData } from '../../../../types/auth';
import { notify } from '../../../../utils/toast';
import './LoginForm.css';

export const LoginForm = () => {
  const { login, invalid, setInvalid } = useAuth();

  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const toggleShowPass = () => {
    setIsPasswordHidden((state) => !state);
  };

  const handleInput = (e: React.FormEvent<HTMLInputElement>) => {
    setPassword(e.currentTarget.value);
    setInvalid(false);
  };

  const handleSubmit = async () => {
    if (username === '') {
      notify('Please enter username');
      return;
    }
    if (password === '') {
      notify('Please enter password');
      return;
    }
    const loginData: LoginData = {
      username,
      password,
    };
    await login(loginData);
  };

  const preventDefault = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await handleSubmit();
  };

  return (
    <div className="loginForm">
      <form
        onSubmit={preventDefault}
        className="loginFormWrapper"
        id="loginForm"
      >
        <div className="loginFormInputWrapper">
          <label htmlFor="loginUsername" className="loginFormLabel">
            Your Username
          </label>
          <input
            type="text"
            className="loginFormInput"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder='Enter your username'
          />
        </div>

        <div className="loginFormInputWrapper">
          <label htmlFor="loginPass" className="loginFormLabel">
            Your Password
          </label>

          <input
            type={isPasswordHidden ? 'password' : 'text'}
            className="loginFormInput loginFormInputPass"
            value={password}
            onChange={handleInput}
            placeholder='Enter your password'
          />

          <span className="loginFormShowHideBtn" onClick={toggleShowPass}>
            <div
              className={`${
                isPasswordHidden ? 'loginEye loginEye--show' : 'loginEye'
              }`}
            >
              <img src={eyeOff} alt="eyeIcon" />
            </div>
          </span>
        </div>
      </form>
      {invalid ? <span className="loginError">Invalid credentials</span> : null}
      <button
        form="loginForm"
        className={`btn btnGradientMain loginFormSubmit scale`}
        onClick={handleSubmit}
      >
        Sign In
      </button>
      <div>
        <p className="loginFormText">If you forgot your password or having </p>
        <p className="loginFormText">
          troubles with getting in,{' '}
          <a
            href={LINK_TO_API_SUPPORT}
            target="_blank"
            className="link-gradient"
            rel="noreferrer"
          >
            Contact Retro Bridge API Support
          </a>
        </p>
      </div>
    </div>
  );
};
