import { apiUrls } from "../constants/urls";
import { useCallback } from "react";
import {client} from "../service/client";


export function useMetrics() {
    const getTotalInfo = useCallback(async (token: string) => {
        try {
            const {data: transactions} = await client.get(apiUrls.totalTransactions, {
                headers: {
                    'Authorization': `${token}`
                }
            });
            const {data: fees} = await client.get(apiUrls.totalFees, {
                headers: {
                    'Authorization': `${token}`
                }
            });
            return {transactions, fees: fees?.fee_amount}
        } catch (err) {
            console.error(err);
        }
    }, []);

    const getFeeRebate = useCallback(async (token: string, fromDate:string, toDate:string) => {
        const isParams = fromDate && toDate
        try {
            const {data} = await client.get(apiUrls.feeRebate, {
                params : isParams ? {
                    fromDate,
                    toDate
                } : {},
                headers: {
                    'Authorization': `${token}`
                }
            });
            return data
           
        } catch (err) {
            console.error(err);
        }
    }, []);

    const getChartInfo = useCallback(async (token: string, fromDate:string, toDate:string) => {
        const isParams = fromDate && toDate
        try {
            const {data} = await client.get(apiUrls.chart, {
                params : isParams ? {
                    fromDate,
                    toDate,
                    groupBy: 'day'
                } : {
                    groupBy: 'day'
                },
                headers: {
                    'Authorization': `${token}`
                }
            });
            return data

        } catch (err) {
            console.error(err);
        }
    }, []);


    return {
        getTotalInfo,
        getFeeRebate,
        getChartInfo
    };
}
