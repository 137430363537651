import FolderIcon from "../../../Assets/mainPage/empty-tokens-folder.svg";
import MugnifyGlassIcon from "../../../Assets/mainPage/empty-tokens-mugnify.svg";
import EmptyChartBgImg from '../../../Assets/mainPage/empty-chart-state-background.png'
import React from "react";

export const EmptyFeeState = () => {
    return (
        <div className="empty-fee-state-container">
            <div className="empty-fee-state-images">
                <img className="empty-fee-state-bg-img" src={EmptyChartBgImg} alt=""/>
                <img className="empty-fee-state-folder-img" src={FolderIcon} alt=""/>
                <img className="empty-fee-state-mug-img" src={MugnifyGlassIcon} alt=""/>
            </div>
            <div className="empty-fee-state-text">There is no data for the selected period</div>
        </div>
    )
}