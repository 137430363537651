import React, {useEffect, useMemo, useRef, useState} from 'react';
import {DatePickerComponent} from "./DatePickerComponent";
import {Tooltip} from "./Tooltip";
import {useUserInfoContext} from "../../../context/UserInfoContext";
import {handleDateChange} from "../../../utils/handleDateChange";
import {useChart} from "../../../hooks/useChart";
import {EmptyChartState} from "./EmptyChartState";



export const ChartCard = () => {
    const container = useRef<HTMLDivElement | null>(null)
    const [activeTab, setActiveTab] = useState(0)

    const {chartInfo, setToDateChart, setFromDateChart, handleCalendarDatesChangeChart} = useUserInfoContext()

    const {top, left, tooltipVisible, tooltipInfo} = useChart(container, chartInfo)

    useEffect(() => {
        handleDateChange(activeTab, setFromDateChart, setToDateChart)
    }, [activeTab, setFromDateChart, setToDateChart])



    return (
        <div className="main-page-info-card-bg chart-card">
            <div className="chart-card-top" style={{
                marginBottom: '44px'
            }}>
                <div className="card-text-small">Transactions Over Time</div>
                <DatePickerComponent
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    handleCalendarDatesChange={handleCalendarDatesChangeChart}
                />
            </div>
            {!!chartInfo.length && <div ref={container} className="cart-container"></div>}
            {!!chartInfo.length && <Tooltip tooltipVisible={tooltipVisible} left={left} top={top} info={tooltipInfo}/>}
            {!chartInfo.length && <EmptyChartState />}
        </div>
    );
};
