import {Coordinate} from "lightweight-charts";

const toolTipHeight = 168
const toolTipMargin = 16;

export const calculateTooltipTopPosition = (chart: HTMLDivElement, coordinate: Coordinate) => {
    const emptyChartSpaceFromTheBottom = chart.clientHeight - toolTipHeight - toolTipMargin
    const emptyChartSpaceFromTheTop = coordinate + toolTipMargin

    const additionalPixels = emptyChartSpaceFromTheBottom > emptyChartSpaceFromTheTop ? 120 : 70

    const coordinateY =
        coordinate - toolTipHeight - toolTipMargin > 0
            ? coordinate - toolTipHeight - toolTipMargin
            : Math.max(
                0,
                Math.min(
                    emptyChartSpaceFromTheBottom,
                    emptyChartSpaceFromTheTop
                )
            );

    return {coordinateY, additionalPixels}
}