import {RefObject, useEffect, useRef} from "react";

export function useOnClickOutside(
    node: RefObject<HTMLDivElement>,
    handler?: () => void
) {
    const handlerRef = useRef(handler);
    useEffect(() => {
        handlerRef.current = handler;
    }, [handler]);

    useEffect(() => {
        const handleClickOutside = (e: any) => {
            if (node.current?.contains(e.target) ?? false) {
                return;
            }
            if (handlerRef.current) handlerRef.current();
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [node]);
}