import React from 'react'
import { Navigate } from 'react-router-dom'
import { shallow } from 'zustand/shallow'
import { useUserStore } from '../../store/useUserStore'
import {LOGIN_PAGE} from "../../constants/routes.constants";

export const PrivateRoute = ({ children }: {children: React.ReactElement}) => {
    const { user } = useUserStore(({ user }) => ({ user }), shallow)

    return user !== null ? children : <Navigate to={LOGIN_PAGE} />
}
